<template>
    <div class="pay">
        <transition name="poput">
            <div class="poput-bg">
                <div class="poput-fixBottom">
                    <div class="title">
                        <span @click="$emit('close')"><img src="@/assets/images/close.png" alt=""></span>
                        支付费用
                    </div>
                    <div class="content">
                        <div class="price">¥<span>{{money}}</span></div>
                        <div class="pay">
                            <div class="name">确认支付</div>
                            <ul class="type-list">
                                <li v-if="from==1">
                                    <div class="radio-con"><input type="radio" name="type" v-model="payType" value="2"><label></label></div>
                                    支付宝支付
                                </li>
                                <li>
                                    <div class="radio-con"><input type="radio" name="type" v-model="payType" value="1"><label></label></div>
                                    微信支付
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="btn-group">
                        <button class="btn btn-default" @click="$emit('pay',payType)">立即支付</button>
                        <!-- <a class="btn btn-default" href="tel:18663921982">联系客服</a> -->
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import Vue from "vue";
export default {
    props: {
        money: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            from:Vue.getUser().from,
            payType: this.from == 1 ? 2 : 1,
        }
    },
    mounted() {
    },
    methods: {},
}
</script>
<style lang="scss" scoped>
.poput-fixBottom {
    background: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    // height: 820px;
    position: absolute;
    .title {
        border-bottom: 1px solid #f1f2f3;
        line-height: 36px;
        font-size: 36px;
        text-align: center;
        padding: 30px 32px;
        span {
            float: left;
            height: 36px;
            width: 36px;
        }
    }
    .content {
        padding: 32px 0;
        .price {
            text-align: center;
            font-size: 49px;
            line-height: 80px;
            padding: 44px 0;
            color: #353535;
            border-bottom: 1px solid #f1f2f3;
            span {
                font-size: 80px;
            }
        }
        .pay {
            padding: 32px;
            .name {
                font-size: 34px;
                line-height: 48px;
                text-align: left;
            }
            .type-list {
                margin: 56px 0;
                display: flex;
                align-items: center;
                justify-content: center;
                li {
                    flex-grow: 1;
                    text-align: center;
                    font-size: 34px;
                    line-height: 48px;
                }
            }
        }
    }
    .btn-group {
        padding: 0 32px 72px 32px;
        button {
            background: #d51f13;
            border-radius: 8px;
            width: 100%;
            height: 88px;
            color: #fff;
            font-size: 32px;
            border: none;
        }
    }
}
</style>