<template>
    <div class="class">
        <div class="top-head">
            <div class="return" @click="$router.go(-1)"><img src="@/assets/images/return.png"></div>
            <div class="text-center">课程详情</div>
            <div class="edit" @click="share"><img src="@/assets/images/course/share.png" alt=""></div>
        </div>
        <div class="video-con" v-if="classData.length>=curIndex+1">
            <video :src="classData[curIndex].video_url" controls></video>
            <div class="title">
                {{classData[curIndex].name}}
                <span>视频时长：{{classData[curIndex].chapter_time}}分钟</span>
                <!-- <div class="relevance-class" v-if="relevance_class!=''">深入学习可购买《{{relevance_class}}》课程观看</div> -->
            </div>
        </div>
        <ul class="class-list" ref="class" @scroll="getNextPage" :class="{hasRel:relevance_class!=''}">
            <li v-for="item,index in classData" @click="change(item,index)" :class="{on:index==curIndex}">
                <template v-if="detailData.class_type!=2 && detailData.buy!=1">
                    <span v-if="item.is_free==0">免费</span>
                    <img v-else src="@/assets/images/course/lock_red.png" alt="">
                </template>
                {{index+1}} {{item.name}}
            </li>
            <div class="have-none" v-show="isLast"><span>已加载全部</span></div>
        </ul>
        <!-- 支付 -->
        <pay v-if="payShow" :money="detailData.money" @close="payShow=false" @pay="pay"></pay>
        <div v-html="alipayWap" ref="alipayWap"></div>
        <!-- 推广 -->
        <share v-if="shareShow" :data="detailData" @close="shareShow=false"></share>
    </div>
</template>
<script>
import Vue from "vue";
import pay from "../common/pay";
import share from "../common/share";
export default {
    components: { pay, share },
    data() {
        return {
            classData: [],
            id: this.$route.query.id,
            curIndex: this.$route.query.index,
            isLast: false,
            page: 1,
            pageNum: 0,
            require: true,
            noData: false,
            relevance_class: '',
            payShow: false,
            shareShow: false,
            detailData: {},
            from: Vue.getUser().from,
            uid: Vue.getUser().uid,
            name: Vue.getUser().name,
            payType: this.from == 1 ? 2 : 1,
            code: this.$route.query.code ? this.$route.query.code : '',
            alipayWap: '',
        }
    },
    mounted() {
        this.getCapoter();
        if (this.curIndex > 19) {
            for (var i = 1; i < this.curIndex / 20; i++) {
                this.page++;
                this.getCapoter();
            }
        }
        this.getDetail();
        if (this.code != "" && this.from == 2) {
            this.payType = 1;
            this.payReq();
        }
    },
    methods: {
        share() {
            if (this.from == 2) {
                this.shareShow = true
            } else if (this.from == 1) {
                var u = navigator.userAgent;
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
                if (isiOS) {
                    window.webkit.messageHandlers.Share.postMessage({
                        "nickName": this.name,
                        "courseName": this.detailData.class_name,
                        "courseImage": this.domainUrl + this.detailData.photo,
                        "courseDescription": this.detailData.share_desc ? this.detailData.share_desc : this.detailData.feature,
                        "sharedQRCodeUrl": "http://school.hailuoguniang.com/#/course/login?parent_id=" + this.detailData.id
                    });
                } else if (isAndroid) {
                    SharedUtils.showShared(this.name, this.detailData.class_name, this.domainUrl + this.detailData.photo, this.detailData.share_desc ? this.detailData.share_desc : this.detailData.feature, "http://school.hailuoguniang.com/#/course/login?parent_id=" + this.detailData.id);
                }
            }

        },
        payReq() {
            this.$ajax.send({
                url: "/course/pay",
                data: { class_id: this.id, code: this.code, type: this.from, pay_type: this.payType, uid: this.uid },
                type: "post",
                success: data => {
                    if (!!data && data.code == "1001") {
                        if (this.from == 1) {
                            window.location.href = data.data.mweb_url;
                        } else {
                            if (typeof WeixinJSBridge == "undefined") {
                                if (document.addEventListener) {
                                    document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(data.data), false);
                                } else if (document.attachEvent) {
                                    document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(data.data));
                                    document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(data.data));
                                }
                            } else {
                                this.onBridgeReady(data.data)
                            }
                        }

                    }
                }
            })
        },
        onBridgeReady(data) {
            var self = this;
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {
                "appId": data.appId,     //公众号名称，由商户传入     
                "timeStamp": data.timeStamp,         //时间戳，自1970年以来的秒数     
                "nonceStr": data.nonceStr, //随机串     
                "package": data.package,
                "signType": data.signType,         //微信签名方式：     
                "paySign": data.sign //微信签名 
            },
                function (res) {
                    if (res.err_msg === "get_brand_wcpay_request:ok") {
                        self.getDetail();
                        self.capoterList = [];
                        self.page = 1;
                        self.getCapoter();
                    } else if (res.err_msg === "get_brand_wcpay_request:fail") {
                        self.getDetail();
                        self.capoterList = [];
                        self.page = 1;
                        self.getCapoter();
                    }
                });
        },
        pay(data) {
            this.payType = data;
            var self = this;
            this.tipShow = false;
            if (this.from == 2) {
                window.location.href = "http://employerapi.hailuoguniang.com/user/new?type=2&id=" + this.id;
            } else {
                if (this.payType == 1) {
                    this.payReq();
                    setTimeout(function () {
                        this.$msgBox.show({
                            title: '温馨提示',
                            content: '请确认微信支付是否已完成',
                            confirmBtnText: '已完成支付',
                            cancelBtnText: '未完成支付',
                            isShowCancelBtn: true,
                            confirm() {
                                self.getDetail();
                                self.capoterList = [];
                                self.page = 1;
                                self.getCapoter();
                            },
                            cancel() {
                                self.getDetail();
                                self.capoterList = [];
                                self.page = 1;
                                self.getCapoter();
                            }
                        })
                    }, 3000);

                } else {
                    var self = this;
                    var url = "/course/pay?pay_type=2&class_id=" + this.id + "&type=" + this.from + "&uid=" + this.uid;
                    this.load(url, function (res) {
                        self.alipayWap = res;
                        self.$nextTick(() => {
                            self.$refs.alipayWap.children[0].submit()
                        })
                    })
                }

            }
        },
        load(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    callback(xhr.response);
                }
            }
            xhr.open('GET', url, true);
            xhr.send('');
        },
        getNextPage() {
            let a = this.$refs.class.scrollHeight;
            let b = this.$refs.class.clientHeight;
            let c = this.$refs.class.scrollTop;
            if (b + c >= a - 50 && this.require && this.pageNum >= 20) {
                this.page++
                this.getCapoter();
            } else if (this.pageNum < 20) {
                this.isLast = true;
            }
        },
        change(item, index) {
            if (item.is_free == 0 || this.detailData.class_type == 2 || this.detailData.buy == 1) {
                this.curIndex = index;
                console.log(this.curIndex)
            } else {
                this.payShow = true;
            }
        },
        getDetail() {
            this.$ajax.send({
                url: "/course/info",
                data: { uid: Vue.getUser().uid, id: this.id },
                type: "post",
                success: data => {
                    if (!!data && data.code == "1001") {
                        this.detailData = data.data;
                    }
                }
            });
        },
        getCapoter() {
            var self = this;
            this.require = false;
            this.$ajax.send({
                url: "/course/chapter/list",
                data: {
                    phone: Vue.getUser().uid,
                    from: Vue.getUser().from,
                    class_id: this.id,
                    page: this.page
                },
                type: "post",
                success: data => {
                    this.require = true;
                    if (!!data && data.code == "1001") {
                        this.classData = this.classData.concat(data.data.list);
                        this.pageNum = data.data.list.length;
                        this.relevance_class = data.data.relevance_class;
                        if (this.classData.length == 0) {
                            this.$msgBox.show({
                                title: '操作提示',
                                content: "暂无可查看章节，请浏览其他优质课程吧",
                                confirmBtnText: "返回课程列表",
                                isShowCancelBtn: false,
                                confirm() {
                                    self.goBack()
                                }
                            })
                        }

                    } else {
                        this.$msgBox.show({
                            title: '操作提示',
                            content: data.message,
                            confirmBtnText: "返回课程列表",
                            isShowCancelBtn: false,
                            confirm() {
                                self.goBack();
                            }
                        })
                    }
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.class {
    padding-top: 88px;
    .return {
        position: absolute;
        width: 40px;
        height: 40px;
        z-index: 10;
        top: 24px;
        left: 32px;

        img {
            height: 34px;
        }
    }
    .video-con {
        video {
            width: 100%;
            height: 422px;
        }
        .title {
            padding: 24px 32px;
            font-size: 30px;
            color: #282d3b;
            span {
                font-size: 28px;
                color: #959595;
                display: block;
                margin-top: 10px;
            }
            .relevance-class {
                margin-top: 10px;
                font-size: 30px;
                color: #d15754;
            }
        }
    }

    .class-list {
        border-top: #f1f2f3 12px solid;
        padding: 10px 32px;
        position: absolute;
        top: 688px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;

        // &.hasRel {
        //     top: 728px;
        // }

        li {
            margin: 24px 0px;
            position: relative;
            padding-left: 46px;
            line-height: 40px;
            font-size: 28px;
            color: #353535;
            span {
                float: right;
                padding-left: 20px;
                color: #d51f13;
                font-size: 28px;
            }
            img {
                width: 50px;
                float: right;
                padding-left: 20px;
                margin: 20px 10px 0 0;
            }
            &.on {
                color: #d15754;

                &::after {
                    position: absolute;
                    content: "";
                    width: 26px;
                    height: 24px;
                    top: 7px;
                    left: 0;
                    background: url("../../../assets/images/course/select.png")
                        left no-repeat;
                    background-size: 100%;
                }
            }
        }
    }
}

.have-none {
    margin: 0;
}
</style>